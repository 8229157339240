const SuccessGreenIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.611 2.28906H6.38685C3.86935 2.28906 2.29102 4.07156 2.29102 6.59406V13.4007C2.29102 15.9232 3.86185 17.7057 6.38685 17.7057H13.6102C16.136 17.7057 17.7077 15.9232 17.7077 13.4007V6.59406C17.7077 4.07156 16.136 2.28906 13.611 2.28906Z"
        stroke="#43E5A9"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.03223 10.0009L9.01056 11.9784L12.9656 8.02344" stroke="#43E5A9" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SuccessGreenIcon;
