// packages
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { useField, useFormikContext } from 'formik';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import PhoneInput, { Country, getCountries, getCountryCallingCode, Value } from 'react-phone-number-input';
//components
import { Modal } from 'system/Modal';
import { Button } from 'system/Button';
import DeleteIcon from 'icons/DeleteIcon';
import AnimateSpin from 'icons/AnimateSpin';
import ConfirmPhoneForm from './ConfirmPhoneForm';
import SuccessGreyIcon from 'icons/SuccessGreyIcon';
import SuccessGreenIcon from 'icons/SuccessGreenIcon';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { FieldErrorMessage } from 'system/FieldErrorMessage';
import LabelComponent from 'system/LabelComponent/LabelComponent';
// types
import { PhoneInputComponentProps, SetupProfileScreenState } from '../types';
// hooks
import { useAuth } from 'authentication';
import { useHandlePhoneBlur } from 'hooks/PhoneInputHooks/useHandlePhoneBlur';
// models
import { BasicInfoFormData } from 'models/modelsOfForms';
// schemas
import { GET_PROFILE_OID_CLAIMS } from 'schemas/profile/ProfileQueries';
import { UPDATE_PROFILE_OID_CLAIM } from 'schemas/profile/ProfileMutations';
// generated
import { ProfileQueriesGetProfileOIDClaimsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileOIDClaimsQuery.graphql';
import { ProfileMutationsUpsertProfileOIDClaimsMutation } from 'schemas/profile/__generated__/ProfileMutationsUpsertProfileOIDClaimsMutation.graphql';

const PhoneInputComponent = ({ name, setFormError, currentUserFromCognito, setCurrentUserFromCognito, onCreateOrUpdateProfileInfo }: PhoneInputComponentProps) => {
  const { values } = useFormikContext<BasicInfoFormData>();
  const { identity } = useAuth();

  const [allCountriesForPhone] = useState<Array<Country>>(getCountries());
  const intl = useIntl();
  const [, { error }, { setValue, setError }] = useField(name);

  const [phoneCodeValue, setPhoneCodeValue] = useState<Value | undefined>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [equalsPhones, setEqualsPhones] = useState<boolean>(false);

  const { getErrorPhoneCode, handlePhoneCodeBlur } = useHandlePhoneBlur(setPhoneCodeValue);

  const [getProfileOIDClaim, setProfileOIDClaim] = useState<SetupProfileScreenState>({
    phoneNumber: null,
    phoneNumberVerified: false,
  });

  const classNames = error
    ? 'block w-ful pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs sm:text-sm rounded-md'
    : 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-xs sm:text-sm border-specialGray-012 rounded-md w-full';

  const inputPhoneRef = useRef<HTMLInputElement>(null);

  const { profileOIDClaims } = useLazyLoadQuery<ProfileQueriesGetProfileOIDClaimsQuery>(GET_PROFILE_OID_CLAIMS, { ids: [+identity?.profileId!], skip: !identity?.profileId });

  useEffect(() => {
    if (profileOIDClaims?.edges?.[0]) {
      setProfileOIDClaim({ phoneNumber: profileOIDClaims?.edges?.[0]?.phoneNumber, phoneNumberVerified: profileOIDClaims?.edges?.[0]?.phoneNumberVerified });
    }
  }, [profileOIDClaims?.edges]);

  const [commitUpdate] = useMutation<ProfileMutationsUpsertProfileOIDClaimsMutation>(UPDATE_PROFILE_OID_CLAIM);

  const getCurrentUserInfo = useCallback(async () => {
    const updatedCurrentUser = await Auth.currentAuthenticatedUser();
    if (setCurrentUserFromCognito) {
      setCurrentUserFromCognito(updatedCurrentUser);
    }
  }, [setCurrentUserFromCognito]);

  const handleClearInput = useCallback(async () => {
    try {
      await Auth.updateUserAttributes(currentUserFromCognito, {
        phone_number: '',
      });
      commitUpdate({
        variables: { username: '' },
      });
      setProfileOIDClaim(prevState => ({ ...prevState, phoneNumber: '' }));
      (inputPhoneRef.current as HTMLInputElement).value = '';
      setPhoneCodeValue('');
      setEqualsPhones(false);
      setValue(undefined);
      onCreateOrUpdateProfileInfo({ ...values, phoneNumber: undefined });
      await getCurrentUserInfo();
    } catch (err) {
      if (setFormError) {
        setFormError(err as Error);
      }
    }
  }, [commitUpdate, currentUserFromCognito, getCurrentUserInfo, onCreateOrUpdateProfileInfo, setFormError, setValue, values]);

  const handleInputBlur = useCallback(() => {
    setValue(`${phoneCodeValue}${inputPhoneRef.current?.value}`);
  }, [phoneCodeValue, setValue]);

  const handleChangePhoneInput = useCallback(() => {
    setEqualsPhones(false);
  }, []);

  const handleOpenModal = useCallback(async () => {
    if (!phoneCodeValue) {
      setError('phone_code_is_empty');
    }

    if (!inputPhoneRef.current?.value) {
      setError('phone_number_is_empty');
    }

    if (phoneCodeValue && inputPhoneRef.current?.value && currentUserFromCognito && !error) {
      setLoading(true);
      try {
        await Auth.updateUserAttributes(currentUserFromCognito, {
          phone_number: `${phoneCodeValue}${inputPhoneRef.current?.value}`,
        });
        commitUpdate({
          variables: { username: '' },
        });
        setError(undefined);
        setShowModal(true);
        setLoading(false);
        setEqualsPhones(true);
      } catch (err) {
        if (setFormError) {
          setFormError(err as Error);
        }
        setLoading(false);
      }
    }
  }, [phoneCodeValue, currentUserFromCognito, error, setError, commitUpdate, setFormError]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleCountryChange = useCallback(
    (country: Country) => {
      if (country) {
        inputPhoneRef.current?.focus();
        setError(undefined);
        setEqualsPhones(false);
      }
    },
    [setError],
  );

  useEffect(() => {
    if (getProfileOIDClaim.phoneNumber) {
      const findCountry = allCountriesForPhone.find(country => getProfileOIDClaim.phoneNumber?.includes(`+${getCountryCallingCode(country)}`));
      if (findCountry) {
        (inputPhoneRef.current as HTMLInputElement).value = getProfileOIDClaim.phoneNumber.replace(`+${getCountryCallingCode(findCountry)}`, '');
        setPhoneCodeValue(`+${getCountryCallingCode(findCountry)}`);
      }

      if (`${phoneCodeValue}${inputPhoneRef.current?.value}` === getProfileOIDClaim.phoneNumber) {
        setEqualsPhones(true);
      }
    } else {
      setEqualsPhones(false);
    }
  }, [getProfileOIDClaim.phoneNumber, phoneCodeValue, allCountriesForPhone]);

  const handleConfirmVerifyPhone = useCallback(async () => {
    await Auth.updateUserAttributes(currentUserFromCognito, {
      phone_number: `${phoneCodeValue}${inputPhoneRef.current?.value}`,
    });
    commitUpdate({
      variables: { username: '' },
    });
    setEqualsPhones(true);
    setShowModal(false);
    onCreateOrUpdateProfileInfo(values);
    await getCurrentUserInfo();
    if (setFormError) {
      setFormError(undefined);
    }
  }, [currentUserFromCognito, phoneCodeValue, commitUpdate, onCreateOrUpdateProfileInfo, values, getCurrentUserInfo, setFormError]);

  const handleResentCode = useCallback(async () => {
    if (getProfileOIDClaim.phoneNumber) {
      setLoading(true);
      try {
        await Auth.verifyUserAttribute(currentUserFromCognito, 'phone_number', { phone_number: getProfileOIDClaim.phoneNumber });
        setShowModal(true);
        setLoading(false);
      } catch (err) {
        if (setFormError) {
          setFormError(err as Error);
        }
        setLoading(false);
      }
    }
  }, [currentUserFromCognito, getProfileOIDClaim.phoneNumber, setFormError]);

  const handleChangePhoneBodyInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputPhoneRef.current) {
      inputPhoneRef.current.value = e.target.value.replace(/\D/g, '');
    }
    if (getProfileOIDClaim.phoneNumber === `${phoneCodeValue}${inputPhoneRef.current?.value}`) {
      setEqualsPhones(true);
    } else {
      setEqualsPhones(false);
    }
  };

  return (
    <div>
      <LabelComponent id="phone_input_id" label={{ id: 'phone_number_form_label' }} classes="block text-sm font-medium text-darkBlue mb-2" />
      <div className="flex flex-col w-full justify-between items-start sm:flex-row">
        <Modal
          show={showModal}
          onClose={handleCloseModal}
          title={{ id: 'phone_modal_confirm_title' }}
          description={{ id: 'phone_modal_confirm_description' }}
          descriptionValue={{ phoneNumber: `${phoneCodeValue}${inputPhoneRef.current?.value}` }}
        >
          <ConfirmPhoneForm onConfirmVerifyPhone={handleConfirmVerifyPhone} loading={loading} onResendCode={handleResentCode} />
        </Modal>

        <div className="flex sm:w-4/5 w-full sm:justify-start justify-between text-darkBlue">
          <div className="flex flex-col w-32">
            <PhoneInput
              internationalIcon={GlobeAltIcon}
              id="phone_input_id"
              onChange={handleChangePhoneInput}
              className={`[&>.PhoneInputInput]:px-0 [&>.PhoneInputCountry>.PhoneInputCountryIcon]:text-specialGray-05 [&>.PhoneInputInput]:focus:ring-0 border border-specialGray-012 focus:ring-blue-500 focus:border-blue-500 h-[38px] ${
                getErrorPhoneCode ? 'border-[rgba(253,164,175,1)]' : ''
              }`}
              value={phoneCodeValue}
              international
              onCountryChange={handleCountryChange}
              onBlur={handlePhoneCodeBlur}
            />
            {getErrorPhoneCode ? <FieldErrorMessage error={getErrorPhoneCode} /> : null}
          </div>
          <div className="flex flex-col w-2/4 relative sm:mx-auto">
            <input
              ref={inputPhoneRef}
              type="text"
              className={classNames}
              placeholder={intl.formatMessage({ id: 'phone_placeholder' })}
              onChange={handleChangePhoneBodyInput}
              onBlur={handleInputBlur}
            />
            <div className="absolute right-4 top-2.5">{getProfileOIDClaim.phoneNumberVerified ? <SuccessGreenIcon /> : <SuccessGreyIcon />}</div>
            {error && <FieldErrorMessage error={error} />}
          </div>
        </div>

        <div
          className={`flex ${getProfileOIDClaim.phoneNumberVerified ? 'sm:w-1/5' : 'sm:w-1/4'} w-full ${
            getProfileOIDClaim.phoneNumberVerified ? 'justify-end' : 'justify-between'
          } mt-4 sm:mt-0`}
        >
          {loading ? (
            <div className={`bg-blue-500 rounded-md flex justify-center items-center h-9 ${getProfileOIDClaim.phoneNumberVerified || !equalsPhones ? 'w-16' : 'w-28'}`}>
              <AnimateSpin />
            </div>
          ) : (
            <>
              {!getProfileOIDClaim.phoneNumberVerified && !equalsPhones && (
                <Button type="button" label={{ id: 'phone_verify_label' }} buttonType="secondary" onClick={handleOpenModal} additionalClasses="w-max" />
              )}
              {!getProfileOIDClaim.phoneNumberVerified && equalsPhones && (
                <Button
                  type="button"
                  label={{ id: 'confirm_resendCta' }}
                  buttonType="secondary"
                  onClick={handleResentCode}
                  disabled={getProfileOIDClaim.phoneNumberVerified}
                  additionalClasses="w-max"
                />
              )}
            </>
          )}
          <div className="cursor-pointer mt-1.5" onClick={handleClearInput}>
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneInputComponent;
