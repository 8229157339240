// packages
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// schemas
import { VESSEL_FRAGMENT } from 'schemas/services/ServiceFragments';
// components
import { Modal } from 'system/Modal';
import { Button } from 'system/Button';
import SearchBy from 'system/SearchBy';
import ShipPhoto from 'icons/ShipPhoto';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
import { IVesselType } from 'models/IVessels';
import { InputGroup } from 'system/InputGroups/InputGroup';
import SelectInputGroup, { OptionType } from 'system/SelectInputGroup';
// models
import { IVesselKind } from 'models/IVesselKind';
// generated
import { ServiceFragmentsVessel$key } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';
import { ServiceFragmentsVesselKind$data } from 'schemas/services/__generated__/ServiceFragmentsVesselKind.graphql';
import { ServiceQueriesDetailsVesselQuery, ServiceQueriesDetailsVesselQuery$data } from 'schemas/services/__generated__/ServiceQueriesDetailsVesselQuery.graphql';
// schemas
import { DETAILS_VESSEL } from 'schemas/services/ServiceQueries';
// types
import { VesselInformationProps } from '../types';
import { IServiceFormData } from 'models/modelsOfForms';

const VesselInformation = ({
  dataOfVesselKind,
  defaultShowModal = false,
  maxInputLength,
  ServicesData,
  isResetForm,
  setGetDetailsVessel,
  vesselKinds,
  handleError,
  globalError,
}: VesselInformationProps) => {
  const { touched } = useFormikContext<IServiceFormData>();

  const [showModal, setShowModal] = useState<boolean>(defaultShowModal);
  const [fleetmonId, setFleetmonId] = useState<string | null>('');
  const [selectedType, setSelectedType] = useState<ServiceFragmentsVesselKind$data | null>(dataOfVesselKind);
  const [, { value }] = useField('vessel.image');

  const vesselData = useFragment<ServiceFragmentsVessel$key>(VESSEL_FRAGMENT, ServicesData?.vessel || null);
  const getDetailsVessel = useLazyLoadQuery<ServiceQueriesDetailsVesselQuery>(DETAILS_VESSEL, { vesselID: +fleetmonId!, skip: !fleetmonId });
  const [detailsVessel, setDetailsVessel] = useState<ServiceQueriesDetailsVesselQuery$data['vessel'] | null>(null);
  const [types, setTypes] = useState<IVesselKind>(vesselKinds || []);

  useEffect(() => {
    if (fleetmonId) {
      setDetailsVessel(getDetailsVessel.vessel);
    } else {
      setDetailsVessel(null);
    }
  }, [fleetmonId, getDetailsVessel.vessel]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleChangeType = useCallback(
    (value: string) => {
      if (value) {
        setTypes(() => vesselKinds.filter(({ name }: OptionType) => name?.toLowerCase().includes(value.toLowerCase())) as IVesselKind);
      } else {
        setTypes(() => vesselKinds);
      }
    },
    [vesselKinds],
  );

  const getImageShip = useCallback(() => {
    if (value && detailsVessel?.name) {
      return <img className="w-full h-auto" src={value} alt={detailsVessel?.name} />;
    } else if (vesselData?.image && vesselData.name) {
      return <img className="w-full h-auto" src={vesselData.name} alt={vesselData.name} />;
    } else {
      return null;
    }
  }, [value, detailsVessel?.name, vesselData?.image, vesselData?.name]);

  const handleGetVesselId = useCallback(
    (item: IVesselType | null) => {
      if (item && globalError?.message !== 'service_not_exist_vessel_by_imo') {
        setFleetmonId(item.fleetmonID);
        if (handleError) {
          handleError(null);
        }
      } else {
        setFleetmonId('');
        setGetDetailsVessel(null);
        if (handleError && touched.vessel?.imo) {
          handleError(new Error('service_not_exist_vessel_by_imo'));
        }
      }
      closeModal();
    },
    [closeModal, globalError?.message, handleError, setGetDetailsVessel, touched.vessel?.imo],
  );

  useEffect(() => {
    if (!isEmpty(getDetailsVessel) && getDetailsVessel.vessel && globalError?.message !== 'service_not_exist_vessel_by_imo') {
      setSelectedType(() => vesselKinds.find(({ name }: { name: string }) => name === getDetailsVessel.vessel?.kind?.name) as ServiceFragmentsVesselKind$data);
      setGetDetailsVessel(getDetailsVessel);
    }
    if (!fleetmonId) {
      setGetDetailsVessel(null);
    }
  }, [getDetailsVessel, fleetmonId, setGetDetailsVessel, vesselKinds, globalError?.message]);

  return (
    <>
      <Modal
        onClose={closeModal}
        show={showModal}
        title={{ id: 'search_by_vessel_name' }}
        description={{ id: 'search_by_description' }}
        childrenClass="flex h-[calc(100%_-_64px)] pt-8 sm:pt-0"
        additionalClasses="h-[calc(100vh_-_16px)]"
      >
        <SearchBy onGetVesselId={handleGetVesselId} />
      </Modal>

      <section className="flex flex-col sm:flex-row sm:justify-between">
        {value ? (
          <section className="mr-0 w-full h-36 sm:mr-6 sm:w-1/5 sm:h-20 overflow-hidden flex items-center justify-center rounded-xl">{getImageShip()}</section>
        ) : (
          <section className="bg-avatar-bg mr-0 w-full h-36 sm:mr-5 sm:w-1/5 sm:h-20 flex items-center justify-center mx-auto rounded-xl">
            <span className="w-[37px] h-[33px]">
              <ShipPhoto />
            </span>
          </section>
        )}

        {/* hardcoded part, update it */}
        <div className="sm:w-4/5 w-full relative flex flex-col mt-5 sm:mt-0 justify-between">
          <div className="absolute right-0 top-0 flex items-center h-5">
            <Button onClick={openModal} type="button" buttonType="white-secondary" label={{ id: 'search_by_vessel_name' }} xsText />
          </div>
          <PinCodev2
            name="vessel.imo"
            label={{ id: 'service_form_vessel_imo' }}
            maxLength={maxInputLength}
            onGetVesselId={handleGetVesselId}
            inputType="number"
            globalError={globalError}
          />
        </div>
      </section>

      <section className="flex flex-row">
        <div className="w-full sm:w-8/12 sm:mr-5">
          <InputGroup name="vessel.name" label={{ id: 'field_is_name' }} placeholderText={{ id: 'field_is_name' }} />
        </div>
        <div className="hidden sm:flex w-2/6">
          <SelectInputGroup
            name="vessel.kind"
            options={[...types].sort((a, b) => a.name.localeCompare(b.name))}
            label={{ id: 'service_form_type_label' }}
            onChangeSelect={handleChangeType}
            placeholder={{ id: 'placeholder_form_type' }}
            defaultSelectedElement={selectedType}
            setSelectedItem={setSelectedType}
            selectedItem={selectedType}
            isResetInput={isResetForm}
            autoComplete="off"
          />
        </div>
      </section>

      <div className="mt-5">
        <InputGroup name="vessel.operatorName" label={{ id: 'service_form_vessel_operator_name' }} placeholderText={{ id: 'service_form_vessel_operator_name' }} />
      </div>

      <div className="sm:hidden flex w-full">
        <SelectInputGroup
          name="vessel.kind"
          options={[...types].sort((a, b) => a.name.localeCompare(b.name))}
          label={{ id: 'service_form_type_label' }}
          onChangeSelect={handleChangeType}
          placeholder={{ id: 'placeholder_form_type' }}
          defaultSelectedElement={selectedType}
          setSelectedItem={setSelectedType}
          selectedItem={selectedType}
          isResetInput={isResetForm}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default VesselInformation;
