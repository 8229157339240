// packages
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
// components
import VesselsList from './VesselsList';
import SpinnerIcon from 'icons/SpinnerIcon';
import { SearchInputIcon } from 'icons/SearchInputIcon';
// models
import { SearchByProps } from 'models/modelsOfComponents';
//error boundary
import ErrorBoundaryWithRetry from './ErrorBoundaryWithRetry';

const SearchBy: React.FC<SearchByProps> = ({ defaultSearchValue, defaultNotSkipQuery, onGetVesselId, defaultAttemptsNumber = 0, trackingCallback }) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setValueInput] = useState<string | undefined>('');
  const [isSkipQuery, setIsSkipQuery] = useState<boolean>(true);
  const [attempts, setAttempts] = useState<number>(defaultAttemptsNumber);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (defaultSearchValue && defaultNotSkipQuery) {
      setValueInput(defaultSearchValue);
      setIsSkipQuery(false);
    }
  }, [defaultSearchValue, defaultNotSkipQuery]);

  const handleOnChange = debounce(() => {
    setAttempts(0);
    if (inputRef?.current?.value.length! >= 3) {
      setValueInput(inputRef?.current?.value);
      setIsSkipQuery(false);
    } else {
      setIsSkipQuery(true);
    }
  }, 500);

  const handleBlur = useCallback(() => {
    if (trackingCallback && inputRef?.current?.value) {
      trackingCallback(inputRef.current.value);
    }
  }, [trackingCallback]);

  return (
    <div className={`w-full flex flex-col ${inputRef?.current?.value.length! >= 3 && 'sm:h-96'}`}>
      <div className="relative w-full flex items-center mb-1.5 sm:mt-5">
        <SearchInputIcon className="w-4 h-4 text-grayIcon absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1 ml-4" />
        <input
          className="h-12 shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-sm sm:text-base border-specialGray-012 placeholder-specialGray-05 text-darkBlue rounded-md w-full pl-10 pt-2 bg-specialGray-002"
          type="text"
          ref={inputRef}
          onChange={handleOnChange}
          placeholder={intl.formatMessage({ id: 'type_vessel_name' })}
          onBlur={handleBlur}
          data-test-id="search-by"
        />
      </div>

      <ErrorBoundaryWithRetry
        attempts={attempts}
        setAttempts={setAttempts}
        fallback={({ error }) => {
          return attempts >= 2 && error && <span className="w-full text-md text-red-600 text-center my-auto">{intl.formatMessage({ id: 'error_boundary_text' })}</span>;
        }}
      >
        {() => {
          return (
            <Suspense
              fallback={
                <div className="flex flex-col w-full items-center justify-center my-auto text-specialGray-075 text-sm">
                  <SpinnerIcon />
                  <span>{intl.formatMessage({ id: attempts === 0 ? 'suspense_loading_time' : 'suspense_loading_retrying' })}</span>
                </div>
              }
            >
              <VesselsList fetchKey={attempts} searchByValue={inputValue} isSkipQuery={isSkipQuery} onGetVesselId={onGetVesselId} />
            </Suspense>
          );
        }}
      </ErrorBoundaryWithRetry>
    </div>
  );
};

export default SearchBy;
