// packages
import { useCallback } from 'react';
// components
import { InputGroup } from 'system/InputGroups/InputGroup';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
// types
import { VesselInformationProps } from '../types';

const VesselInformation = ({ onGetVesselId, globalError }: VesselInformationProps) => {
  const handleTrackingCode = useCallback((imoNumber: string) => {
    window.analytics?.track('wui-signup-step-sea-service-with-chose-imo', {
      imoNumber,
    });
  }, []);

  const handleTrackingVesselNameInput = useCallback((value: string) => {
    window.analytics?.track('wui-signup-step-sea-service-with-chose-vessel-name', {
      vesselName: value,
    });
  }, []);

  return (
    <div className="relative flex flex-col space-y-[18px]">
      <PinCodev2
        name="vesselImo"
        label={{ id: 'service_form_vessel_imo' }}
        maxLength={7}
        onGetVesselId={onGetVesselId}
        inputType="number"
        additionalClasses="mt-2"
        trackingCallback={handleTrackingCode}
        globalError={globalError}
      />
      <InputGroup
        name="vesselName"
        label={{ id: 'hire_information_vessel_name' }}
        placeholderText={{ id: 'placeholder_form_type_without_dots' }}
        trackingCallback={handleTrackingVesselNameInput}
      />
      <input type="hidden" name="fleetmonId" />
      <input type="hidden" name="vesselCnIso" />
      <input type="hidden" name="vesselGrossTonnage" />
      <input type="hidden" name="vesselLength" />
      <input type="hidden" name="vesselKindId" />
      <input type="hidden" name="vesselOperatorName" />
      <input type="hidden" name="vesselImage" />
    </div>
  );
};

export default VesselInformation;
