// packages
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Auth } from 'aws-amplify';
import { useMutation } from 'react-relay';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
// components
import { Button } from 'system/Button';
import ShieldYellowIcon from 'icons/ShieldYellowIcon';
import CustomButton from 'system/Buttons/CustomButton';
import AlertForError from 'system/Alert/AlertForError';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// formHelpers
import { ConfirmFormValidateSchema } from 'formHelpers/validationsOfForms';
import { ConfirmFormByPhoneInitialValues } from 'formHelpers/initialValuesOfForms';
// generated
import { ProfileMutationsUpsertProfileOIDClaimsMutation } from 'schemas/profile/__generated__/ProfileMutationsUpsertProfileOIDClaimsMutation.graphql';
// schemas
import { UPDATE_PROFILE_OID_CLAIM } from 'schemas/profile/ProfileMutations';

const StepWithConfirmCodeByPhone = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState<Error | undefined>();

  const [commitUpdate] = useMutation<ProfileMutationsUpsertProfileOIDClaimsMutation>(UPDATE_PROFILE_OID_CLAIM);

  const formik = useFormik<{ code: string }>({
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: ConfirmFormByPhoneInitialValues,
    validationSchema: ConfirmFormValidateSchema,
  });

  async function onSubmit(d: { code: string }) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', d.code);

      commitUpdate({
        variables: { username: '' },
        onCompleted: () => {
          navigate(`${RoutePaths.ProfileOrganizationBase}/${RoutesPathNames.welcome}`, { state: { isNewAccount: true } });
        },
      });
    } catch (error) {
      setError(error as Error);
    }
  }

  const handleResentCode = useCallback(async () => {
    const currentUserFromCognito = await Auth.currentAuthenticatedUser();
    try {
      await Auth.verifyUserAttribute(currentUserFromCognito, 'phone_number', { phone_number: state?.phoneNumber });
    } catch (err) {
      setError(err as Error);
    }
  }, [state?.phoneNumber]);

  const handleGoBack = useCallback(() => {
    navigate({ pathname: RoutePaths.HireAuthRegisterStepFive }, { state });
  }, [navigate, state]);

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <div className="flex w-full flex-col items-center">
        <ShieldYellowIcon />
        <h1 className="mt-3 mb-1 text-darkBlue font-semibold sm:text-lg">{intl.formatMessage({ id: 'confirm_code_title' })}</h1>
        <h2 className="text-specialGray-075 text-xs sm:text-sm text-center">
          {intl.formatMessage({ id: 'confirm_code_description_part_1' })}&nbsp;
          <span className="font-semibold">{intl.formatMessage({ id: 'confirm_phone_code_description_part_2' })}</span>
        </h2>
      </div>
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
          {error && <AlertForError heading={{ id: 'confirm_failedConfirmOrResend' }} error={error} />}
          <fieldset className="space-y-[18px]">
            <PinCodev2 name="code" maxLength={6} label={{ id: 'default_auth_confirm_digits_code' }} skipQuery inputType="number" autoFocus />
          </fieldset>
          <div className="flex gap-x-5">
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: 'back' }}
              onClick={handleGoBack}
              additionalClasses=" h-12 rounded-[10px] font-medium w-1/2"
              fullSize
              prefixIcon={ChevronLeftIcon}
            />
            <CustomButton
              type="submit"
              label={{ id: 'next' }}
              className="flex bg-blue rounded-[10px] text-white flex-row-reverse h-12 w-1/2 items-center justify-center space-x-3 space-x-reverse disabled:border-none disabled:bg-[#D4D7DD] disabled:text-specialGray-03 disabled:cursor-not-allowed"
              disabled={!formik.values.code || formik.isSubmitting}
            />
          </div>
          <div className="flex text-xs gap-x-2 mx-auto">
            <span className="text-specialGray-075">{intl.formatMessage({ id: 'confirm_resend_code_title' })}</span>
            <button type="button" className="block text-blue" onClick={handleResentCode}>
              {intl.formatMessage({ id: 'confirm_resend_code_description' })}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithConfirmCodeByPhone;
