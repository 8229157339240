// packages
import { Form, FormikProvider, useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-relay';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// types
import { SignUpWithPhoneNumberData } from '../types';
// formHelpers
import { SignUpWithPhoneNumberInitialValues } from 'formHelpers/initialValuesOfForms';
import { SignUpWithPhoneNumberValidateSchema } from 'formHelpers/validationsOfForms';
// components
import { Button } from 'system/Button';
import Footer from '../components/Footer';
import PhoneComponent from '../StepWithEnterName/PhoneComponent';
import StepWithPhoneIcon from 'icons/registerSteps/StepWithPhoneIcon.webp';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
// generated
import { ProfileMutationsUpsertProfileOIDClaimsMutation } from 'schemas/profile/__generated__/ProfileMutationsUpsertProfileOIDClaimsMutation.graphql';
// schemas
import { UPDATE_PROFILE_OID_CLAIM } from 'schemas/profile/ProfileMutations';

const StepWithPhoneNumber = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState<Error | undefined>();

  const [commitUpdateProfileOIDClaim] = useMutation<ProfileMutationsUpsertProfileOIDClaimsMutation>(UPDATE_PROFILE_OID_CLAIM);

  const formik = useFormik<SignUpWithPhoneNumberData>({
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: SignUpWithPhoneNumberInitialValues,
    validationSchema: SignUpWithPhoneNumberValidateSchema,
  });

  async function onSubmit(data: SignUpWithPhoneNumberData) {
    const currentUserFromCognito = await Auth.currentAuthenticatedUser();

    try {
      await Auth.updateUserAttributes(currentUserFromCognito, {
        phone_number: data.phoneNumber,
      });
      commitUpdateProfileOIDClaim({
        variables: { username: '' },
        onCompleted: () => {
          navigate(RoutePaths.HireAuthRegisterStepSix, { state: { ...state, phoneNumber: data.phoneNumber } });
        },
      });
    } catch (e) {
      setError(e as Error);
      formik.setSubmitting(false);
    }
  }

  const handlePressOnSkipButton = useCallback(() => {
    window.analytics?.track('wui-signup-step-with-enter-most-recent-work-experience-press-on-skip-button');
    navigate(`${RoutePaths.ProfileOrganizationBase}/${RoutesPathNames.welcome}`, { state: { isNewAccount: true } });
  }, [navigate]);

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <div className="flex w-full flex-col items-center">
        <img width={74} height={64} src={StepWithPhoneIcon} alt="step with phone number" />
        <h1 className="mt-3 mb-1 text-darkBlue font-semibold sm:text-lg">{intl.formatMessage({ id: 'enter_phone_number_title' })}</h1>
        <h2 className="text-specialGray-075 text-xs sm:text-sm text-center">{intl.formatMessage({ id: 'enter_phone_number_description' })}&nbsp;</h2>
      </div>
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] gap-y-[18px] h-full justify-between sm:justify-start">
          <PhoneComponent name="phoneNumber" />
          <Footer pathnameToPrevStep={RoutePaths.AuthRegisterStepTree} isDisabled={!!formik.touched?.phoneNumber || !!error} />
          <Button
            id="skip-test-id"
            type="button"
            buttonType="white-primary"
            label={{ id: 'skip' }}
            onClick={handlePressOnSkipButton}
            additionalClasses=" h-12 rounded-[10px] font-medium"
            fullSize
          />
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithPhoneNumber;
