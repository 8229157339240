// packages
import { Suspense, useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useLazyLoadQuery } from 'react-relay';
// components
import LineSvg from 'icons/LineSvg';
import { CheckboxInputGroup } from 'system/CheckboxInputGroup';
import { Modal } from 'system/Modal';
import SearchBy from 'system/SearchBy';
import { Button } from 'system/Button';
import VesselInformation from '../VesselInformation';
import { DateInputGroup } from 'system/DateInputGroup';
import SelectDutyWithTacking from './components/SelectDutyWithTracking';
import VesselInformationSkeleton from 'system/skeletons/SignUpSteps/StepWithSeaServiceForm/components/VesselInformationSkeleton';
// types
import { ServiceFormProps } from '../../types';
import { RegisterWithSeaServiceFrom } from 'auth/SignUpSteps/types';
// hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
// models
import { IVesselType } from 'models/IVessels';
//  generated
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
import { ServiceQueriesDetailsVesselQuery, ServiceQueriesDetailsVesselQuery$data } from 'schemas/services/__generated__/ServiceQueriesDetailsVesselQuery.graphql';
// schemas
import { DETAILS_VESSEL, GET_ALL_DUTIES_QUERY } from 'schemas/services/ServiceQueries';

const SeaServiceForm = ({ handleError, globalError }: ServiceFormProps) => {
  const intl = useIntl();
  const [fleetmonId, setFleetmonId] = useState<string | null>('');
  const [isDisabledTo, setDisabledTo] = useState<boolean>(false);

  const { showWindow, toggleShowWindow } = useToggleShowWindow();
  const { setFieldValue } = useFormikContext<RegisterWithSeaServiceFrom>();
  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false });
  const getDetailsVessel = useLazyLoadQuery<ServiceQueriesDetailsVesselQuery>(DETAILS_VESSEL, { vesselID: +fleetmonId!, skip: !fleetmonId });

  const handleSetDetailsVessel = useCallback(
    (detailsVessel: ServiceQueriesDetailsVesselQuery$data | null) => {
      if (detailsVessel && detailsVessel.vessel) {
        setFieldValue('vesselName', detailsVessel.vessel.name);
        setFieldValue('vesselImo', detailsVessel.vessel.imoNumber);
        setFieldValue('vesselKindId', detailsVessel.vessel.kind?.id);
        setFieldValue('fleetmonId', fleetmonId);
        setFieldValue('vesselOperatorName', detailsVessel.vessel.owner);
        setFieldValue('vesselGrossTonnage', detailsVessel.vessel.grossTonnage);
        setFieldValue('vesselImage', detailsVessel.vessel.imageURL);
        setFieldValue('vesselCnIso', detailsVessel.vessel.cnISO);
        setFieldValue('vesselLength', detailsVessel.vessel.length);
        window.analytics?.track('wui-signup-step-sea-service-with-chose-vessel-name', {
          vesselName: detailsVessel.vessel.name,
        });
      }
    },
    // eslint-disable-next-line
    [fleetmonId],
  );

  const handleResetFields = useCallback(() => {
    setFleetmonId('');
    setFieldValue('vesselImo', '');
    setFieldValue('vesselName', '');
    if (handleError) {
      handleError(new Error('service_not_exist_vessel_by_imo'));
    }
    // eslint-disable-next-line
  }, []);

  const handleGetVesselId = useCallback(
    (item: IVesselType | null) => {
      if (item && globalError?.message !== 'service_not_exist_vessel_by_imo') {
        setFleetmonId(item.fleetmonID);
        if (handleError) {
          handleError(null);
        }
      } else {
        handleResetFields();
      }
      toggleShowWindow(false)();
    },
    // eslint-disable-next-line
    [],
  );

  const handleGetVesselIdWithTracking = useCallback(
    (item: IVesselType | null) => {
      if (item) {
        setFleetmonId(item.fleetmonID);
        window.analytics?.track('wui-signup-step-sea-service-with-chose-vessel-in-searchBy-modal', {
          selectedVessel: item,
        });
      } else {
        handleResetFields();
      }
      toggleShowWindow(false)();
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (!isEmpty(getDetailsVessel) && getDetailsVessel?.vessel) {
      handleSetDetailsVessel(getDetailsVessel);
    }
    if (!fleetmonId) {
      handleSetDetailsVessel(null);
    }
  }, [getDetailsVessel, fleetmonId, handleSetDetailsVessel]);

  const handleTrackingWhenChooseDate = useCallback(
    (nameField: string) => (date: string) => {
      window.analytics?.track(`wui-signup-step-sea-service-with-chose-${nameField}-date`, {
        date,
      });
    },
    [],
  );

  const handleSetCheckbox = useCallback((value: boolean) => {
    setDisabledTo(value);
    window.analytics?.track('wui-signup-step-sea-service-with-chose-i-am-working-here', {
      IWorkInThisPosition: value,
    });
  }, []);

  const handleTrackingSearchByVessel = useCallback((searchValue: string) => {
    window.analytics?.track('wui-signup-step-sea-service-with-entered-vessel-name', {
      searchValue,
    });
  }, []);

  return (
    <>
      <Modal
        onClose={toggleShowWindow(false)}
        show={showWindow}
        title={{ id: 'search_by_vessel_name' }}
        description={{ id: 'search_by_description' }}
        childrenClass="flex h-[calc(100%_-_64px)] pt-8 sm:pt-0"
        additionalClasses="h-[calc(100vh_-_16px)]"
      >
        <SearchBy onGetVesselId={handleGetVesselIdWithTracking} trackingCallback={handleTrackingSearchByVessel} />
      </Modal>
      <SelectDutyWithTacking allDuties={allDuties} nameField="dutyId" autoFocus />
      <div className="mt-3.5 space-y-[18px]">
        <DateInputGroup name="start" label={{ id: 'input_placeholder_from' }} trackingCallback={handleTrackingWhenChooseDate('from')} />
        <DateInputGroup name="end" label={{ id: 'input_placeholder_to' }} disabled={isDisabledTo} trackingCallback={handleTrackingWhenChooseDate('to')} />
        <div className="flex flex-row w-full [&_input]:h-4">
          <CheckboxInputGroup name="workingNow" value={isDisabledTo.toString()} label={{ id: 'service_form_working_now' }} onChange={handleSetCheckbox} />
        </div>
      </div>
      <LineSvg classes="mt-6 mb-7" />
      <h4 className="font-semibold text-darkBlue mb-6">{intl.formatMessage({ id: 'most_recent_sea_service_add_vesel_title' })}</h4>
      <div className="relative">
        <div className="absolute right-0 top-0 flex items-center h-5 z-10" tabIndex={1}>
          <Button onClick={toggleShowWindow(true)} type="button" buttonType="white-secondary" label={{ id: 'search_by_vessel_name' }} xsText />
        </div>
        <Suspense fallback={<VesselInformationSkeleton />}>
          <VesselInformation onGetVesselId={handleGetVesselId} globalError={globalError} />
        </Suspense>
      </div>
    </>
  );
};

export default SeaServiceForm;
