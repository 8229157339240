// packages
import React, { useCallback } from 'react';
import { isEmpty, union } from 'lodash';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
// components
import LineSvg from 'icons/LineSvg';
import ShipPhoto from 'icons/ShipPhoto';
import CountryFlag from './CountryFlag';
import ShipInfoIcon from 'icons/ShipInfoIcon';
// generated
import { ServiceQueriesSearchByQuery } from 'schemas/services/__generated__/ServiceQueriesSearchByQuery.graphql';
// schemas
import { GET_VESSEL_BY_NAME_QUERY } from 'schemas/services/ServiceQueries';
// models
import { IVessels, IVesselType } from 'models/IVessels';
import { FoundVesselsProps } from 'models/modelsOfComponents';

const VesselsList: React.FC<FoundVesselsProps> = ({ searchByValue, isSkipQuery, onGetVesselId = () => {}, fetchKey }) => {
  const intl = useIntl();
  const { vessels } = useLazyLoadQuery<ServiceQueriesSearchByQuery>(GET_VESSEL_BY_NAME_QUERY, { name: searchByValue || '', skip: isSkipQuery }, { fetchKey });
  const zeroImoVessels: IVessels = vessels?.edges?.filter(item => item.imo === 0) as IVessels;
  const vesselsWithImo: IVessels = vessels?.edges?.filter(item => item.imo !== 0) as IVessels;

  const sorting = useCallback((n1: IVesselType, n2: IVesselType) => {
    if (n1.length !== null && n2.length !== null && n1.length < n2.length) {
      return 1;
    }
    if (n1.length !== null && n2.length !== null && n1.length > n2.length) {
      return -1;
    }
    return 0;
  }, []);

  const sortedArrayWithoutImo = zeroImoVessels && Array.prototype.concat(zeroImoVessels)?.sort(sorting);
  const sortedVesselsWithImo = vesselsWithImo && Array.prototype.concat(vesselsWithImo)?.sort(sorting);

  return !isEmpty(union(sortedVesselsWithImo, sortedArrayWithoutImo)) ? (
    <>
      <span className="sm:hidden text-sm text-specialGray-075 mb-6">{intl.formatMessage({ id: 'number_of_vessels_found' }, { number: vessels?.edges?.length || 0 })}</span>
      <div className="flex flex-col sm:max-h-80 max-h-full overflow-y-auto sm:mt-2 sm:border sm:rounded-2xl sm:border-specialGray-012 space-y-6 sm:space-y-0">
        {union(sortedVesselsWithImo, sortedArrayWithoutImo).map(item => {
          return (
            <div
              key={item.fleetmonID}
              className="flex flex-row items-center p-2 cursor-pointer hover:bg-specialGray-005 border border-solid sm:border-b sm:border-0 sm:border-specialGray-012 last:sm:border-none border-b-none rounded-[10px] sm:rounded-none"
              onClick={() => onGetVesselId(item)}
              data-test-id={item.name}
            >
              <div className="flex flex-col w-full">
                <div className="flex justify-between">
                  <div className="w-full flex items-center">
                    <CountryFlag
                      countryName={item.countryName}
                      countryId={item.cnISO}
                      classes="mr-2"
                      customPositionPopover="left-8"
                      customPositionPopoverArrow="-rotate-45 -left-1.5 top-3"
                    />
                    <div className="text-sm font-medium text-specialGray-1">{item.name}</div>
                  </div>
                  {item?.image ? (
                    <img className="sm:hidden mr-5 w-[75px] h-[44px] flex items-center rounded-md" src={item.image} alt={item.name} />
                  ) : (
                    <section className="sm:hidden bg-avatar-bg w-[85px] h-[44px] flex items-center justify-center rounded-md">
                      <span className="w-[37px] h-[33px]">
                        <ShipPhoto />
                      </span>
                    </section>
                  )}
                </div>
                <LineSvg classes="sm:hidden w-[calc(100%_+_16px)] -ml-2 mt-2.5 mb-2" />
                <section className="w-full flex sm:flex-row flex-col sm:justify-start justify-between space-y-1 sm:space-y-0 sm:mt-1">
                  <div className="flex sm:justify-start justify-between">
                    <span className="text-xs text-specialGray-075 sm:mr-2">{intl.formatMessage({ id: 'sea_service_type' })}</span>
                    <span className="text-xs text-specialGray-1">{item.kind.name}</span>
                  </div>
                  <div className="flex sm:justify-start justify-between">
                    <span className="text-xs text-specialGray-075 sm:mr-2 sm:ml-4">{intl.formatMessage({ id: 'search_bar_country' })}</span>
                    <span className="text-xs text-specialGray-1">{item.countryName}</span>
                  </div>
                  {!!item.imo && item.imo !== 0 && (
                    <div className="flex sm:justify-start justify-between">
                      <span className="text-xs text-specialGray-075 sm:mr-2 sm:ml-4">{intl.formatMessage({ id: 'sea_service_modal_more_imo_vessel' })}</span>
                      <span className="text-xs text-specialGray-1">{item.imo}</span>
                    </div>
                  )}
                  <div className="flex sm:justify-start justify-between">
                    <span className="text-xs text-specialGray-075 sm:mr-2 sm:ml-4">{intl.formatMessage({ id: 'search_bar_length' })}</span>
                    <span className="text-xs text-specialGray-1">{item.length} m</span>
                  </div>
                </section>
              </div>
            </div>
          );
        })}
      </div>
    </>
  ) : (
    <>
      {vessels && isEmpty(vessels?.edges) && (
        <section className="text-center">
          <div className="mx-auto w-8 mt-16">
            <ShipInfoIcon />
          </div>
          <h2 className="flex justify-center text-base font-semibold text-specialGray-1 mt-5">{intl.formatMessage({ id: 'vessels_not_found' })}</h2>
          <p className="mt-5 mx-auto text-center text-specialGray-075 w-7/12">{intl.formatMessage({ id: 'vessels_not_found_text' })}</p>
        </section>
      )}
    </>
  );
};

export default VesselsList;
