import React from 'react';

const SuccessGreyIcon: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.612 0.664062H5.38783C2.55651 0.664062 0.666992 2.69123 0.666992 5.59406V12.4007C0.666992 15.3063 2.55147 17.3307 5.38783 17.3307H12.6112C15.4483 17.3307 17.3337 15.3063 17.3337 12.4007V5.59406C17.3337 2.68865 15.4485 0.664062 12.612 0.664062ZM5.38783 1.91406H12.612C14.7374 1.91406 16.0837 3.35987 16.0837 5.59406V12.4007C16.0837 14.635 14.7373 16.0807 12.6112 16.0807H5.38783C3.26263 16.0807 1.91699 14.6352 1.91699 12.4007V5.59406C1.91699 3.36271 3.26727 1.91406 5.38783 1.91406ZM12.4084 6.58084C12.1643 6.33677 11.7686 6.33677 11.5245 6.58084L8.01071 10.0935L6.47497 8.55825L6.40486 8.49775C6.16014 8.31626 5.81293 8.3365 5.59109 8.55844C5.34706 8.80257 5.34714 9.19829 5.59127 9.44232L7.56961 11.4198L7.63971 11.4803C7.88438 11.6618 8.23154 11.6416 8.4534 11.4197L12.4084 7.46473L12.4689 7.39463C12.6505 7.14995 12.6303 6.80273 12.4084 6.58084Z"
        fill="rgba(8, 32, 81, 0.12)"
        fillOpacity="1"
      />
    </svg>
  );
};

export default SuccessGreyIcon;
