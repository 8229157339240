// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import StepWithPassword from 'auth/SignUpSteps/StepWithPassword/StepWithPassword';
import StepWithEnterName from 'auth/SignUpSteps/StepWithEnterName/StepWithEnterName';
import HireSignUpStepsLayout from 'system/Layouts/SignUpStepsLayout/HireSignUpStepsLayout';
import StepWithConfirmCode from 'auth/SignUpSteps/StepWithConfirmCode/StepWithConfirmCode';
import StepWithPhoneNumber from 'auth/SignUpSteps/StepWithPhoneNumber/StepWithPhoneNumber';
import StepWithEmailAndPhone from 'auth/SignUpSteps/StepWithEmailAndPhone/StepWithEmailAndPhone';
import StepWithConfirmCodeByPhone from 'auth/SignUpSteps/StepWithConfirmCodeByPhone/StepWithConfirmCodeByPhone';
// enums
import { PLATFORM_GROUP } from 'models/enums';

export const HireRegisterRoute = {
  path: `${RoutePaths.HireAuthRegisterBySteps}/*`,
  Component: HireSignUpStepsLayout,
  children: [
    {
      path: RoutesPathNames.stepOne,
      element: <StepWithEmailAndPhone registerType={PLATFORM_GROUP.HIRE} />,
    },
    {
      path: RoutesPathNames.stepTwo,
      element: <StepWithPassword registerType={PLATFORM_GROUP.HIRE} />,
    },
    {
      path: RoutesPathNames.stepThree,
      element: <StepWithConfirmCode registerType={PLATFORM_GROUP.HIRE} />,
    },
    {
      path: RoutesPathNames.stepFour,
      element: <StepWithEnterName registerType={PLATFORM_GROUP.HIRE} />,
    },
    {
      path: RoutesPathNames.stepFive,
      Component: StepWithPhoneNumber,
    },
    {
      path: RoutesPathNames.stepSix,
      Component: StepWithConfirmCodeByPhone,
    },
  ],
};
