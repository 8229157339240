// packages
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import { Button } from 'system/Button';
import AnimateSpin from 'icons/AnimateSpin';
import AlertForError from 'system/Alert/AlertForError';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
// models
import { ConfirmPhoneFormProps } from '../types';
import { IPhoneConfirmForm } from 'models/modelsOfForms';
// formHelpers
import { CodeConfirmValidateSchema } from 'formHelpers/validationsOfForms';
import { PhoneConfirmInitialValues } from 'formHelpers/initialValuesOfForms';

const ConfirmPhoneForm = ({ onConfirmVerifyPhone, onResendCode, loading }: ConfirmPhoneFormProps) => {
  const [error, setError] = useState<Error | undefined>();

  const formik = useFormik<IPhoneConfirmForm>({
    onSubmit,
    initialValues: PhoneConfirmInitialValues,
    validationSchema: CodeConfirmValidateSchema,
  });

  async function onSubmit(data: IPhoneConfirmForm) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', data.codeConfirm);
      if (onConfirmVerifyPhone) {
        onConfirmVerifyPhone();
      }
    } catch (err) {
      setError(err as Error);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        {error && <AlertForError heading={{ id: 'confirm_failedConfirmOrResend' }} error={error} />}
        <PinCodev2 name="codeConfirm" maxLength={6} label={{ id: 'default_auth_confirmCodeLabel' }} inputType="string" skipQuery autoFocus />
        <div className="flex justify-between mt-8">
          {loading ? (
            <div className="bg-blue-200 rounded-md flex justify-center items-center h-8 w-28">
              <AnimateSpin />
            </div>
          ) : (
            <Button label={{ id: 'confirm_resendCta' }} buttonType="secondary" type="button" onClick={onResendCode} />
          )}
          <Button label={{ id: 'confirm' }} type="submit" />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default ConfirmPhoneForm;
