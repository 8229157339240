import React from 'react';
//types
import { ErrorBoundaryWithRetryProps } from '../models/commonTypes';

class ErrorBoundaryWithRetry extends React.Component<ErrorBoundaryWithRetryProps, { error: Error | null }> {
  state = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }

  retryRequest = () => {
    this.setState(() => {
      return {
        error: null,
      };
    });

    this.props.setAttempts(prev => prev + 1);
  };

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (!error) {
      return children();
    }
    if (this.props.attempts < 3) {
      this.retryRequest();
      return;
    } else {
      return fallback({ error });
    }
  }
}
export default ErrorBoundaryWithRetry;
